import * as React from "react";
import firebase from "firebase";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { useHistory, useLocation } from "react-router-dom";
import { hostname } from "os";

export interface CommingSoonOverlayProps {}

const CommingSoonOverlay: React.FunctionComponent<CommingSoonOverlayProps> =
  () => {
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const location = useLocation();

    const blackListUrl = ["/", "/forgotPassword", "/register"];

    const goToHotFSBO = () => {
      window.location.href = "https://leadmachine.hotfsbo.com";
    };
    const signOut = async () => {
      try {
        // TODO: Put signout as a global function
        await firebase.auth().signOut();
        localStorage.removeItem("persist:root");
        localStorage.removeItem("persist:userInfoState");
        history.push("/");
      } catch (err) {
        console.log(err);
        // Do something
      }
    };

    React.useEffect(() => {
      if (blackListUrl.includes(location.pathname)) {
        setOpen(false);
      } else {
        // setOpen(false);
        setOpen(true);
      }
    }, [location]);
    return (
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Coming Soon
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        The Hot FSBO Lead Machine is under construction. Watch
                        your email to stay tuned.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6 mt-5 sm:mt-6">
                  <div className="col-span-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={goToHotFSBO}
                    >
                      Learn More about our product
                    </button>
                  </div>
                  <div className="col-span-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300 sm:text-sm"
                      onClick={signOut}
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

export default CommingSoonOverlay;
