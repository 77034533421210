import * as React from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import firebase from 'firebase'
import { getAllLeads } from "../api/requests/leadRequests";
import { getUsersById } from "../api/requests/userRequests";
import LeadActionMenu from "../components/LeadActionMenu";
import { AuthContext } from "../FirebaseAuthContext";
import { LeadType } from "../types";

export interface LeadPageProps {}

const LeadPage: React.FunctionComponent<LeadPageProps> = () => {
  const UserContext = React.useContext(AuthContext)
  const {user} = UserContext
  const [selected, setSelected] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [leads, setLeads] = React.useState<LeadType[]>([
  {
    name: "Jane Cooper",
    phoneNumber: "5647785575",
    email: "jane.cooper@example.com",
    campaignName: "testin Campaign",
    formName: "test form",
    platform: "Windows",
    selected: false,
  },
])
  const toggleSelected = () => setSelected(!selected);


  const toggleSpecificLead = (id: number) => {
    const tempLeads = leads;
    tempLeads[id].selected = !tempLeads[id].selected;
    setLeads([...tempLeads]);
  };

  const fillLeads = async () => {
    try {
      const leadsResponse = await firebase.firestore().collection('leads').doc(user.uid).get()
      if(!leadsResponse.data()){
          //TODO: Get a better one
          throw new Error('Something went wrong try again later')
      }
      //@ts-expect-error
      const leadsRaw = Object.values<LeadType>(leadsResponse.data().info);
      const leads = leadsRaw.map((lead) => ({ selected: false, ...lead }));
      setLeads(leads);
    } catch (err) {
      //TODO: Set up error messages
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fillLeads();
  }, []);

  const RenderLeadList = () =>
    React.useMemo(() => {
      return leads.map((person, id) => (
        <tr
          key={id}
          onClick={() => (selected ? toggleSpecificLead(id) : {})}
          className={classNames(
            selected ? "hover:bg-gray-50 cursor-pointer" : ""
          )}
        >
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <input
              type="checkbox"
              name="selected"
              checked={person.selected}
              onChange={() => toggleSpecificLead(id)}
              id=""
              className={classNames(
                !selected && "hidden",
                "mr-1 form-checkbox h-3 w-3 text-blue-600"
              )}
            />
            {person.name || "NO NAME"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {person.email || "NO EMAIL"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {person.phoneNumber || "NO PHONE"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <Link to="">{person.campaignName || "NO CAMPAIGN"}</Link>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {person.formName || "NO Form Name"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {person.platform || "NO Platform"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            <a href="#" className="text-indigo-600 hover:text-indigo-900">
              Edit
            </a>
          </td>
        </tr>
      ));
    }, [leads, selected, loading]);
  return (
    <div className="py-10">
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Leads
              </h1>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                <button
                  type="button"
                  onClick={toggleSelected}
                  className={classNames(
                    selected ? "bg-blue-300" : "bg-white hover:bg-gray-50",
                    "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  )}
                >
                  Select
                </button>
                <LeadActionMenu />
                {/* <button
                  type="button"
                  className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  Actions
                </button> */}
              </span>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-4">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Phone Number
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Campaign
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Form
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Platform
                        </th>
                        <th scope="col" className="px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {RenderLeadList()}
                    </tbody>
                  </table>
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">1</span> to{" "}
                        <span className="font-medium">10</span> of{" "}
                        <span className="font-medium">20</span> results
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <a
                        href="#"
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Previous
                      </a>
                      <a
                        href="#"
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Next
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LeadPage;
