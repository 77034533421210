import { AnyAction } from "redux";
import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from "../actions/notificationAction";
interface NotificationType {
    type: 'warning' | 'success' | 'error' | 'info';
    message: string;
    isShown: boolean;

}
const initialState: NotificationType = {
    type:'success',
    message: '',
    isShown: false
};

export default function NotificationReducer(
  state = initialState,
  action: AnyAction
) {
    switch(action.type){

    case SHOW_NOTIFICATION: {
      return {
        ...state,
        isShown: true,
        message: action.payload.message,
        type: action.payload.type,
      };
    }
    case HIDE_NOTIFICATION: {
        return {
            ...state,
            isShown: false,
        }
    }
    default:
      return state;

    }
}
