import * as React from "react";
import firebase from 'firebase'
import { Link, useHistory } from "react-router-dom";
import classes from "classnames";
import localStorage from 'redux-persist/lib/storage'
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon} from "@heroicons/react/outline";
import { UserCircleIcon } from "@heroicons/react/solid"
import { useLocation } from "react-router-dom";
import logo from '../../assets/logo_no_text.png'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
export interface MainNavProps {}

const MainNav: React.FunctionComponent<MainNavProps> = () => {
  const location = useLocation();
  const history = useHistory()

  const navBlackList = ['/','/register','/forgotPassword', '/resetPassword']

  const dropdownRoutes = [
    {
      href: "/settings",
      routeName: "Settings",
    },
  ];
  const routes = [
    {
      href: "/dashboard",
      routeName: "Dashboard",
    },
    {
      href: "/leads",
      routeName: "Leads",
    },
  ];
  /**
   * TODO: Remove this
   * Change to something that uses the login state
   */
    const handleLogOut = async () => {
        try {
            await firebase.auth().signOut()
            localStorage.removeItem('persist:root')
            localStorage.removeItem('persist:userInfoState')
            history.push('/')
        } catch (err) {
            console.log(err)
            // Do something
        }
    }
    if (!navBlackList.includes(location.pathname)) {
    return (
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src={logo}
                      alt="logo"
                    />
                    <img
                        className="hidden lg:block h-8 w-auto"
                        src={logo}
                        alt="logo"
                    />
                    <h1 className='text-2xl font-bold'>Lead Machine</h1>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    {routes.map((route) => {
                      const routeMatch = location.pathname === route.href;
                      const linkClass = classes({
                        ...(routeMatch
                          ? {
                              "border-indigo-500": true,
                              "text-gray-900": true,
                            }
                          : {
                              "border-transparent": true,
                              "hover:border-gray-300": true,
                              "hover:text-gray-700": true,
                              "text-gray-500": true,
                            }),
                        "inline-flex": true,
                        "items-center": true,
                        "px-1": true,
                        "pt-1": true,
                        "border-b-2": true,
                        "text-sm": true,
                        "font-medium": true,
                      });
                      return (
                        <Link to={route.href} className={linkClass}>
                          {route.routeName}
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span className="sr-only">Open user menu</span>
                            <UserCircleIcon className="h-8 w-8 rounded-full" />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                          >
                            {dropdownRoutes.map((route) => {
                              return (
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={route.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {route.routeName}
                                    </Link>
                                  )}
                                </Menu.Item>
                              );
                            })}
                            <Menu.Item>
                              {({ active }) => (
                                <button

                                    onClick={handleLogOut}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block w-full text-left px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                {routes.map((route) => {
                  const routeMatch = location.pathname === route.href;
                  const linkClass = classes({
                    ...(routeMatch
                      ? {
                          "bg-indigo-50": true,
                          "border-indigo-500": true,
                          "text-indigo-700": true,
                        }
                      : {
                          "text-gray-500": true,
                          "hover:bg-gray-50": true,
                          "hover:border-gray-300": true,
                          "hover:text-gray-700": true,
                          "border-transparent": true,
                        }),
                    block: true,
                    "pl-3": true,
                    "pr-4": true,
                    "py-2": true,
                    "border-l-4": true,
                    "text-base": true,
                    "font-medium": true,
                  });
                  return (
                    <Link to={route.href} className={linkClass}>
                      {route.routeName}
                    </Link>
                  );
                })}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                {/* <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      Tom Cook
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      tom@example.com
                    </div>
                  </div>
                  <button className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div> */}
                <div className="mt-3 space-y-1">
                  {dropdownRoutes.map((route) => {
                    return (
                      <Link
                        to={route.href}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                      >
                        {route.routeName}
                      </Link>
                    );
                  })}

                  <button
                      onClick={() => {console.log('Signning out')}}
                    className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }
  return <React.Fragment></React.Fragment>;
};

export default MainNav;
