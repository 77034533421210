import * as React from "react";
import classNames from "classnames";
//import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline";

export interface CampaignCardProps {
  email: string;
  campaignId: string;
  imageUrl: string;
  nickName: string;
  onClick?: (id: string) => void;
}

const CampaignCard: React.FunctionComponent<CampaignCardProps> = ({
  campaignId,
  onClick,
  email,
  imageUrl,
  nickName,
}) => {
  const campaignPriceStats = [
    { label: "Total Cost", value: "$100.00" },
    { label: "Percent", value: "100%" },
  ];
  const campaignCostStats = [
    { label: "CPM", value: "41.3" },
    { label: "CPI", value: "0.3" },
    { label: "CPL", value: "1.5" },
    { label: "CPR", value: "1.5" },
    { label: "Reach", value: "1.5" },
    { label: "Impressions", value: "1.5" },
  ];
  const shouldHover = onClick !== undefined;
  return (
    <li
      key={email}
      className={classNames(
        shouldHover && "cursor-pointer hover:shadow-xl",
        "col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200"
      )}
      onClick={shouldHover ? () => onClick!(campaignId) : () => {}}
    >
      <div className="flex-1 flex flex-col p-6">
        <div className="flex justify-between">
          {/* <div className="flex-wrap flex-none">
                {campaignPriceStats.map((stats) => {
                return (
                <div className="mx-1 my-1">
                <p className="text-sm font-medium text-gray-500 truncate ">
                {stats.label}
                </p>
                <p className="text-md font-semibold text-gray-900">
                {stats.value}
                </p>
                </div>
                );
                })}
                </div> */}
          <div className="flex-grow">
            <img
              className="w-32 h-32 flex-shrink-0 bg-black rounded-md mx-auto"
              src={imageUrl}
              alt=""
            />
          </div>
        </div>
        <h3 className="mt-6 text-gray-900 text-md font-medium text-center">
          {nickName}
        </h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          {/** flex justify-center items-end */}
          <dd className="mt-3 flex justify-between">
            <div>
              <dl>
                {/* <p className="text-sm font-medium text-gray-500 truncate ">
                      Leads
                      </p> */}
              </dl>
              <dt className="flex items-end">
                {/* <p className="text-2xl font-semibold text-gray-900">1000</p> */}

                {/* <p
                  className={classNames(
                    true ? "text-green-600" : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold p-1"
                  )}
                >
                  {true ? (
                    <ArrowSmUpIcon
                      className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowSmDownIcon
                      className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  )}
                  17%
                </p> */}
              </dt>
            </div>

            {/* <div className="flex flex-1 px-2 justify-between flex-wrap">
                {campaignCostStats.map((stats) => {
                return (
                <div className="mx-1">
                <p className="text-sm font-medium text-gray-500 truncate ">
                {stats.label}
                </p>
                <p className="text-md font-semibold text-gray-900">
                {stats.value}
                </p>
                </div>
                );
                })}
                </div> */}
          </dd>
        </dl>
      </div>
    </li>
  );
};

export default CampaignCard;
