import { AnyAction } from "redux";
interface TodoType {
  id: number;
  text: string;
  completed: boolean;
}
const initialState: TodoType[] = [
  { id: 0, text: "something", completed: true },
];

function nextTodoId(todos: TodoType[]) {
  const maxId = todos.reduce(
    (maxId: number, todo: TodoType) => Math.max(todo.id, maxId),
    -1
  );
  return maxId + 1;
}

export default function todosReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case "todos/todoAdded": {
      return [
        ...state,
        {
          id: nextTodoId(state),
          text: action.payload,
          completed: false,
        },
      ];
    }
    default:
      return state;
  }
}
