import { Dispatch } from "redux"

export const LOGIN_USER = 'login_user'
export const LOGOUT_USER = 'logout_user'


const loginUser = (user: any) => ({
    type: LOGIN_USER,
    payload: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        campaigns: user.campaigns
    }
})

const logoutUser = () => ({
    type: LOGOUT_USER,
})

export const loginUserInfo = ( dispatch: Dispatch ) => (user: any) => dispatch(loginUser(user))
export const logOutUserInfo = ( dispatch: Dispatch ) => () => dispatch(logoutUser())
