import axios from "../axios";

export const uploadFbImage = async (
  imageFile: string,
  user: string,
  location: string
) => {
  try {
    const response = await axios({
      method: "post",
      url: "/campaigns/image/fb",
      headers: {
        accept: "application/json",
      },
      data: { picture: imageFile, userId: user, location },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
export const createCampaign = async (campaign: any, user: string) => {
  try {
    const response = await axios({
      method: "post",
      url: "/campaigns",
      data: { campaign, userId: user },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getSpecificCampaign = async (campaignId: string) => {
  try {
    const response = await axios({
      method: "get",
      url: `/campaigns/${campaignId}`,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteCampaign = async (campaignId: string) => {
  try {
    const response = await axios({
      method: "delete",
      url: `/campaigns/${campaignId}`,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
