import axios from "../axios";

export const getUsersById = async (id: string) => {
  try {
    const response = await axios({
      method: "get",
      params: {
        id,
      },
      url: "/getUserById",
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const createUser = async (
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  password: string
) => {
  try {
    const response = await axios({
      method: "post",
      data: {
        firstName,
        lastName,
        email,
        password,
        phoneNumber: phone,
      },
      url: "/users",
    });
    return response;
  } catch (err) {
    throw err;
  }
};
