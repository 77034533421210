import React, {createContext,useState, useEffect} from 'react';
import firebase from 'firebase';

interface ChangeStateType {
    userPresent: boolean,
    userDataPresent: boolean,
    user: any,
    listener: any
}

interface FirebaseAuthContextType {

}

export const AuthContext= createContext<ChangeStateType>({userPresent:false,user:null, userDataPresent: false, listener: null})

const FirebaseAuthContext:React.FunctionComponent<FirebaseAuthContextType> = ({ children }) => {


    let [state,changeState] = useState<ChangeStateType>({
        userPresent: false,
        userDataPresent:false,
        user:null,
        listener:null
    })

    useEffect(()=>{

        console.log('listener null?', state)
        if(state.listener === null){


            changeState({...state,
                         listener:firebase.auth().onAuthStateChanged((user)=>{
                            console.log('onAuthStateChange', user)
                            if(user)
                                changeState(oldState=>({...oldState,userDataPresent:true,user}));
                                else
                                changeState(oldState=>({...oldState,userDataPresent:true,user:null}));
                        })
            });

        }
    return ()=>{
      if(state.listener)
        state.listener()
    }

    },[])


    return (
        <AuthContext.Provider value={state}>
            {children}
        </AuthContext.Provider>
    )
}

export default FirebaseAuthContext
