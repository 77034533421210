import { combineReducers } from "redux";
// import filtersReducer from "./features/filters/filtersSlices";
import todosReducer from "./features/todos/todosSlice";
import CampaignReducer from "./reducers/CampaignReducer";
import NotificationReducer from "./reducers/NotificationReducer";
import { persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import localStorage from 'redux-persist/lib/storage'
import UserReducer from "./reducers/UserReducer";

const rootReducer = combineReducers({
  todos: todosReducer,
  campaigns: CampaignReducer,
  notifications: NotificationReducer,

  userInfo: persistReducer({ key: 'userInfoState', storage: localStorage, stateReconciler: hardSet }, UserReducer)
  // filters: filtersReducer,
});

export default rootReducer;
