import React from 'react';
import { CheckCircleIcon, XIcon, XCircleIcon, InformationCircleIcon, ExclamationIcon } from '@heroicons/react/solid'
import classnames from 'classnames'

interface PopupNotificationType {
    message: string,
    type: 'success'| 'error' | 'warning'| 'info'
}

const PopupNotification: React.FunctionComponent<PopupNotificationType> = ({message, type}) => {

    const iconStyles = React.useMemo(() => (classnames({
        'h-5': true,
        'w-5': true,
        'text-green-400': type === 'success',
        'text-blue-400': type ==='info',
        'text-yellow-400': type === 'warning',
        'text-red-400': type === 'error'
    })),[type])

    const notificationStyles = React.useMemo(() => ({
        container: classnames({
            'rounded-md': true,
            'p-4': true,
            'bg-green-50': type === 'success',
            'bg-yellow-50': type === 'warning',
            'bg-red-50': type === 'error',
            'bg-blue-50': type === 'info'
        }),
        message: classnames({
            'text-sm': true,
            'font-medium': true,
            'text-green-800': type === 'success',
            'text-red-800': type === 'error',
            'text-yellow-800': type === 'warning',
            'text-blue-800': type === 'info',

        }),
        closeBtn: classnames({
            'inline-flex': true,
            'rounded-md': true,
            'p-1.5': true,
            'focus:outline-none': true,
            'focus:ring-2': true,
            'focus:ring-offset-2': true,
            'bg-green-50': type === 'success',
            'text-green-500': type === 'success',
            'hover:bg-green-100': type==='success',
            'focus:ring-offset-green-50': type === 'success',
            'focus:ring-green-600': type === 'success',

            'bg-yellow-50': type === 'warning',
            'text-yellow-500': type === 'warning',
            'hover:bg-yellow-100': type==='warning',
            'focus:ring-offset-yellow-50': type === 'warning',
            'focus:ring-yellow-600': type === 'warning',

            'bg-red-50': type === 'error',
            'text-red-500': type === 'error',
            'hover:bg-red-100': type==='error',
            'focus:ring-offset-red-50': type === 'error',
            'focus:ring-red-600': type === 'error',

            'bg-blue-50': type === 'info',
            'text-blue-500': type === 'info',
            'hover:bg-blue-100': type==='info',
            'focus:ring-offset-blue-50': type === 'info',
            'focus:ring-blue-600': type === 'info'
        }),
    }
    ),[type])
    const getIcon = React.useMemo(() => {
        switch(type){
                case 'success': {
                    return( <CheckCircleIcon className={iconStyles} aria-hidden="true" /> )
                }
                case 'error': {
                    return ( <XCircleIcon className={iconStyles} aria-hidden="true" /> )
                }
                case 'warning': {
                    return ( <ExclamationIcon className={iconStyles} aria-hidden="true" /> )
                }
                case 'info':{
                    return ( <InformationCircleIcon className={iconStyles} aria-hidden="true" /> )
                }
                default: {
                    return ( <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" /> )
                }
        }

    },[type, iconStyles])

    return (
        <div className={notificationStyles.container}>
            <div className="flex">
                <div className="flex-shrink-0">
                    {getIcon}
                </div>
                <div className="ml-3">
                    <p className={notificationStyles.message}>{message}</p>
                </div>
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button type="button" className={notificationStyles.closeBtn}>
                            <span className="sr-only">Dismiss</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PopupNotification;
