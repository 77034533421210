import { AnyAction } from "redux";
import {
  CAMPAIGN_CLOSE_MODAL,
  CAMPAIGN_CLOSE_TOAST,
  CAMPAIGN_CREATE_NEW_CAMPAIGN,
  CAMPAIGN_CREATE_NEW_CAMPAIGN_FAILURE,
  CAMPAIGN_CREATE_NEW_CAMPAIGN_SUCCESS,
  CAMPAIGN_GETTING_CURRENT_CAMPAIGN,
  CAMPAIGN_SET_CURRENT_CAMPAIGN,
  CAMPAIGN_SET_CURRENT_CAMPAIGN_FAILURE,
} from "../actions/campaignActions";
import { CampaignType } from "../types";

interface CampaignStateType {
  currentCampaign: CampaignType | null;
  isModalOpen: boolean;
  loading: boolean;
  isToastOpen: boolean;
  toastType: "success" | "failure";
  toastMessage: string;
}
const initialState: CampaignStateType = {
  currentCampaign: null,
  isModalOpen: false,
  loading: false,
  isToastOpen: false,
  toastType: "success",
  toastMessage: "",
};

export default function CampaignReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case CAMPAIGN_CLOSE_TOAST: {
      return {
        ...state,
        isToastOpen: false,
      };
    }
    case CAMPAIGN_CLOSE_MODAL: {
      return {
        ...state,
        loading: false,
        currentCampaign: null,
        isModalOpen: false,
      };
    }
    case CAMPAIGN_CREATE_NEW_CAMPAIGN: {
      return {
        ...state,
        currentCampaign: null,
        isModalOpen: true,
      };
    }
    case CAMPAIGN_CREATE_NEW_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        isModalOpen: false,
        loading: false,
        isToastOpen: true,
        toastType: "success",
        toastMessage: "Successfully Created New Campaign",
      };
    }
    case CAMPAIGN_CREATE_NEW_CAMPAIGN_FAILURE: {
      return {
        ...state,
        isModalOpen: false,
        loading: false,
        isToastOpen: true,
        toastType: "failure",
        toastMessage: "Could Not Create Campaign",
      };
    }
    case CAMPAIGN_GETTING_CURRENT_CAMPAIGN: {
      return {
        ...state,
        loading: true,
        isModalOpen: true,
      };
    }
    case CAMPAIGN_SET_CURRENT_CAMPAIGN: {
      return {
        // Again, one less level of nesting to copy
        ...state,
        currentCampaign: action.payload,
        loading: false,
      };
    }
    case CAMPAIGN_SET_CURRENT_CAMPAIGN_FAILURE: {
      return {
        ...state,
        isModalOpen: false,
        currentCampaign: null,
        loading: false,
        toastType: "failure",
        isToastOpen: true,
        toastMessage: action.payload,
      };
    }
    default:
      return state;
  }
}
