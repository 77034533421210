import { Dispatch } from "redux";
import {
  createCampaign,
  getSpecificCampaign,
  uploadFbImage,
} from "../api/requests/campaignRequests";

export const CAMPAIGN_CLOSE_MODAL = "campaign/closeModal";
export const CAMPAIGN_CLOSE_TOAST = "campaign/closeToast";
export const CAMPAIGN_GETTING_CURRENT_CAMPAIGN =
  "campaign/gettingCurrentCampaign";

export const CAMPAIGN_CREATE_NEW_CAMPAIGN = "campaign/createNewCampaign";
export const CAMPAIGN_CREATE_NEW_CAMPAIGN_SUCCESS =
  "campaign/createNewCampaignSuccess";
export const CAMPAIGN_CREATE_NEW_CAMPAIGN_FAILURE =
  "campaign/createNewCampaignFailure";

export const CAMPAIGN_SET_CURRENT_CAMPAIGN = "campaign/setCurrentCampaign";
export const CAMPAIGN_SET_CURRENT_CAMPAIGN_FAILURE =
  "campaign/setCurrentCampaignFailure";

const campaignCloseModal = () => ({
  type: CAMPAIGN_CLOSE_MODAL,
});
const campaignCloseToast = () => ({
  type: CAMPAIGN_CLOSE_TOAST,
});
const campaignGettingCurrentCampaign = () => ({
  type: CAMPAIGN_GETTING_CURRENT_CAMPAIGN,
});
const campaignSetCurrentCampaignFailure = (message: string) => ({
  type: CAMPAIGN_SET_CURRENT_CAMPAIGN_FAILURE,
  payload: message,
});
const campaignSetCurrentCampaign = (campaign: any) => ({
  type: CAMPAIGN_SET_CURRENT_CAMPAIGN,
  payload: campaign,
});

const campaignCreateNewCampaign = () => ({
  type: CAMPAIGN_CREATE_NEW_CAMPAIGN,
});

const campaignCreateNewCampaignSuccess = () => ({
  type: CAMPAIGN_CREATE_NEW_CAMPAIGN_SUCCESS,
});
const campaignCreateNewCampaignFailure = () => ({
  type: CAMPAIGN_CREATE_NEW_CAMPAIGN_FAILURE,
});

export const closeModal = (dispatch: Dispatch) => () => {
  dispatch(campaignCloseModal());
};
export const openCreateCampaignModal = (dispatch: Dispatch) => () => {
  dispatch(campaignCreateNewCampaign());
};

export const createNewCampaign =
  (dispatch: Dispatch) =>
  async (campaign: any, image: string, user: string) => {
    try {
      const imageResponse = await uploadFbImage(image, user, campaign.location);
      const { hash, url } = imageResponse.data;
      // // async call here
      campaign.imageHash = hash;
      campaign.imageUrl = url;
      await createCampaign(campaign, user);

      dispatch(campaignCreateNewCampaignSuccess());
    } catch (err) {
      dispatch(campaignCreateNewCampaignFailure());
      throw err;
    } finally {
      setTimeout(() => dispatch(campaignCloseToast()), 2000);
    }
  };
export const getCampaignById =
  (dispatch: Dispatch) => async (campaignId: string) => {
    try {
      dispatch(campaignGettingCurrentCampaign());
      // async call here
      const result = await getSpecificCampaign(campaignId);
      dispatch(campaignSetCurrentCampaign(result.data));
    } catch (err) {
      dispatch(campaignSetCurrentCampaignFailure(err.message));
    } finally {
      setTimeout(() => dispatch(campaignCloseToast()), 2000);
    }
  };
