import React from 'react'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import {useForm} from 'react-hook-form'
import { LockClosedIcon } from "@heroicons/react/solid";




interface ForgetPasswordFormType {

}
//TODO: Add error popups
//TODO: Add sucessfull popups

const ForgotPasswordForm: React.FunctionComponent<ForgetPasswordFormType> = () => {
    const [loading, setLoading] = React.useState(false)
    const { handleSubmit, register } = useForm()

    const submit = async (data: Record<string, string>) => {
        setLoading(true)
        try {
           await firebase.auth().sendPasswordResetEmail(data.email, {url: 'http://localhost:3000'})
        } catch (err) {
            console.log('err',err)
            // Do something
        } finally {
            setLoading(false)
        }
    }

    return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit(submit)}>
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            id="email-address"
            type="email"
            autoComplete="email"
            //required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Email address"
          {...register('email')}
          />
        </div>


        <div className="text-sm flex justify-end py-2">
          <Link
            to='/'
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Login
          </Link>
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
              aria-hidden="true"
            />
          </span>
          {loading ? 'Loading...' : 'Send'}
        </button>
      </div>
    </form>
    )
}

export default ForgotPasswordForm
