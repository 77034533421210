import React, { useContext } from 'react';
import { AuthContext } from './FirebaseAuthContext';
import { Route, Redirect } from 'react-router-dom';


interface ProtectedRouteType {
  redirectTo: string,
  path: string
}

const ProtectedRoute: React.FunctionComponent<ProtectedRouteType> = ({redirectTo, path, children}) => {
  const authValue = useContext(AuthContext)

    if (authValue.userDataPresent){
        if(authValue.user==null){
            return(<Redirect to={redirectTo}></Redirect>)
        }
        else{
            return(

            <Route exact path={path}>
                {children}
            </Route>)
        }
    }
    else{

        return null
    }
}

export default ProtectedRoute
