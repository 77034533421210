import * as React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import placeHolderImage from "../assets/placeHolderImage.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import { createNewCampaign } from "../actions/campaignActions";
import { useDispatch } from "react-redux";
import { AuthContext } from "../FirebaseAuthContext";

export interface CreateCampaignFormProps {
  imageRef: any;
  image: any;
}
const urlRegex =
  /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/g;
const schema = yup.object().shape({
  campaignNickName: yup.string().required(),
  dailyBudget: yup.number().positive().min(0.01).required(),
  bidAmount: yup.number().positive().min(0.01).required(),
  homeAvgPrice: yup.number().positive().min(0.01).required(),
  city: yup.string().required(),
  cbLink: yup.string().matches(urlRegex).required(),
  privacyLink: yup.string().matches(urlRegex).required(),
});
const CreateCampaignForm: React.FunctionComponent<CreateCampaignFormProps> = ({
  imageRef,
  image,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const authValue = React.useContext(AuthContext);
  const dispatch = useDispatch();
  const actions = React.useMemo(
    () => ({
      createCampaign: createNewCampaign(dispatch),
    }),
    [dispatch]
  );
  const handleOpenFile = () => {
    imageRef.current.click();
  };
  /**
   * 
      {
        dailyBudget: "100",
        bidAmount: 2,
        imageHash: campaign.imageHash,
        imageThumbnail: campaign.thumbnailUrl,
        learnMoreLink: "https://hotfsbo.com",
        homeAvgPrice: "299,000",
      },
      {
        city: "Los Angeles",
        callbackLink: "https://hotfsbo.com",
        privacyLink: "https://hotfsbo.com/privacy",
   */
  const formSubmit = (data: any) => {
    const imageFile = image.split(",")[1];
    try {
      actions.createCampaign(
        {
          location: data.city,
          dailyBudget: data.dailyBudget,
          bidAmount: data.bidAmount,
          learnMoreLink: data.cbLink,
          homeAvgPrice: data.homeAvgPrice,
          callBackLink: data.cbLink,
          privacyLink: data.privacyLink,
          nickName: data.campaignNickName,
        },
        imageFile,
        authValue.user.uid
      );
    } catch (error) {
      console.log(error);
    }
  };
  // const formSubmit = React.useCallback(() => {
  //   // const imageFile = new FormData();
  //   // imageFile.append("file", image);
  //   const imageFile = image.split(",")[1];
  //   try {
  //     actions.createCampaign({location: }, imageFile, authValue.user.uid);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [image]);

  React.useEffect(() => {
    console.log(errors);
  }, [errors]);
  React.useEffect(() => {
    console.log("image", image);
  }, [image]);
  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Campaign Photo
        </h3>
        <div className="flex-grow cursor-pointer" onClick={handleOpenFile}>
          <img
            className="w-32 h-32 mx-3 flex-shrink-0 bg-black rounded-md "
            src={image ? image : placeHolderImage}
            alt=""
          />
        </div>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form>
          <fieldset>
            <legend className="block text-xl font-medium text-gray-700">
              Ad Details
            </legend>
            <div className="grid grid-cols-6 gap-6 pb-2">
              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="campaignNickName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Campaign Nickname
                </label>
                <input
                  type="text"
                  {...register("campaignNickName")}
                  id="campaignNickName"
                  placeholder="High Budget with River photo"
                  className={classnames(
                    errors.campaignNickName
                      ? "border-red-300 placeholder-red-400"
                      : "border-gray-300",
                    "mt-1 border focus:outline-none py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md"
                  )}
                />
                {errors.campaignNickName && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Required
                  </p>
                )}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="dailyBudget"
                  className="block text-sm font-medium text-gray-700"
                >
                  Daily Budget
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span
                      className={classnames(
                        errors.dailyBudget ? "text-red-300" : "text-gray-500",
                        "sm:text-sm"
                      )}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    {...register("dailyBudget")}
                    id="dailyBudget"
                    className={classnames(
                      errors.dailyBudget
                        ? "border-red-300 placeholder-red-400"
                        : "border-gray-300",
                      "focus:ring-indigo-500 border focus:border-indigo-500 block w-full py-2 pl-7 pr-12 sm:text-sm rounded-md"
                    )}
                    placeholder="0.00"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className={classnames(
                        errors.dailyBudget ? "text-red-300" : "text-gray-500",
                        "sm:text-sm"
                      )}
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                {errors.dailyBudget && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Required
                  </p>
                )}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="bidAmount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Bid Amount
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span
                      className={classnames(
                        errors.bidAmount ? "text-red-300" : "text-gray-500",
                        "sm:text-sm"
                      )}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    {...register("bidAmount")}
                    id="bidAmount"
                    className={classnames(
                      errors.bidAmount
                        ? "border-red-300 placeholder-red-400"
                        : "border-gray-300",
                      "focus:ring-indigo-500 border focus:border-indigo-500 block w-full py-2 pl-7 pr-12 sm:text-sm rounded-md"
                    )}
                    placeholder="0.00"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className={classnames(
                        errors.bidAmount ? "text-red-300" : "text-gray-500",
                        "sm:text-sm"
                      )}
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                {errors.bidAmount && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Required
                  </p>
                )}
              </div>

              {/* <div className="col-span-6">
                <label
                  htmlFor="zipCodes"
                  className="block text-sm font-medium text-gray-700"
                >
                  Zip Codes
                </label>
                <input
                  id="zipCodes"
                  type="text"
                  {...register("zipCodes")}
                  placeholder="92124,90231,90001"
                  className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div> */}
            </div>
          </fieldset>
          <fieldset className="">
            <legend className="block text-xl font-medium text-gray-700">
              Form Details
            </legend>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  type="text"
                  {...register("city")}
                  id="city"
                  placeholder="Los Angeles"
                  className={classnames(
                    errors.city ? "border-red-300" : "border-gray-300",
                    "mt-1 border focus:outline-none py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md"
                  )}
                />
                {errors.city && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Required
                  </p>
                )}
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                <label
                  htmlFor="homeAvgPrice"
                  className="block text-sm font-medium text-gray-700"
                >
                  Average Home price in your area
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span
                      className={classnames(
                        errors.bidAmount ? "text-red-300" : "text-gray-500",
                        "sm:text-sm"
                      )}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    {...register("homeAvgPrice")}
                    id="homeAvgPrice"
                    className={classnames(
                      errors.homeAvgPrice
                        ? "border-red-300 placeholder-red-400"
                        : "border-gray-300",
                      "focus:ring-indigo-500 border focus:border-indigo-500 block w-full py-2 pl-7 pr-12 sm:text-sm rounded-md"
                    )}
                    placeholder="299000"
                    aria-describedby="price-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className={classnames(
                        errors.homeAvgPrice ? "text-red-300" : "text-gray-500",
                        "sm:text-sm"
                      )}
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                {errors.homeAvgPrice && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Required
                  </p>
                )}
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-6">
                <label
                  htmlFor="cbLink"
                  className="block text-sm font-medium text-gray-700"
                >
                  Website Link
                </label>
                <input
                  type="text"
                  {...register("cbLink")}
                  id="cbLink"
                  placeholder="https://www.homeseller.com"
                  className={classnames(
                    errors.cbLink ? "border-red-300" : "border-gray-300",
                    "mt-1 border focus:outline-none py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md"
                  )}
                />
                {errors.cbLink && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Required
                  </p>
                )}
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-6">
                <label
                  htmlFor="privacyLink"
                  className="block text-sm font-medium text-gray-700"
                >
                  Privacy Link
                </label>
                <input
                  type="text"
                  {...register("privacyLink")}
                  id="privacyLink"
                  placeholder="https://www.homeseller.com/privacy"
                  className={classnames(
                    errors.privacyLink ? "border-red-300" : "border-gray-300",
                    "mt-1 border focus:outline-none py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md"
                  )}
                />
                {errors.privacyLink && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Required
                  </p>
                )}
              </div>
            </div>
          </fieldset>
          <button
            type="button"
            onClick={handleSubmit(formSubmit)}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateCampaignForm;
