import axios from "../axios";

export const validateCode = async (code: string) => {
  try {
    const response = await axios({
      method: "get",
      url: `/codes/verify/${code}`,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const removeCode = async (code: string) => {
  try {
    const response = await axios({
      method: "delete",
      url: `/codes/delete/${code}`,
    });
    return response;
  } catch (err) {
    throw err;
  }
}
