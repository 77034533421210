import * as React from "react";
import firebase from "firebase";
import CampaignCard from "../components/CampaignCard";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "../components/Modal";
import CreateCampaignForm from "../components/CreateCampaignForm";
import CampaignList from "../features/campaignList/CampaignList";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  createNewCampaign,
  openCreateCampaignModal,
} from "../actions/campaignActions";
import { RootState } from "../store/store";
import CurrentCampaign from "../components/CurrentCampaign";
import { createNotification } from "../actions/notificationAction";
import { AuthContext } from "../FirebaseAuthContext";
import FileSelector from "../components/FileSelector";

//TODO: port form production
const CURRENT_URL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/dashboard`;
const CURRENT_URL_CREATE = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${window.location.pathname}/create`;
const CURRENT_URL_CAMPAIGN = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${window.location.pathname}/:campaignId`;
export interface CampaignPageProps {}

const CampaignPage: React.FunctionComponent<CampaignPageProps> = () => {
  const authValue = React.useContext(AuthContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const imageRef = React.useRef<any>();
  const [image, setImage] = React.useState("");

  const state = useSelector((state: RootState) => state);

  const actions = React.useMemo(
    () => ({
      openCreateModal: openCreateCampaignModal(dispatch),
      closeCampaignModal: closeModal(dispatch),
      showNotification: createNotification(dispatch),
      createCampaign: createNewCampaign(dispatch),
    }),
    [dispatch]
  );
  const getBase64 = (file: any, cb: (result: any) => void) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleImageUpload = async (
    e: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  ) => {
    //@ts-expect-error
    const { files } = e.target;
    if (files && files.length) {
      const result = (await new Promise((res) =>
        getBase64(files[0], res)
      )) as string;
      setImage(result);
    }
  };

  const createTempCampaign = async () => {
    try {
      // await actions.createCampaign({}, , authValue.user.uid);
    } catch (err) {
      const { error } = err.response.data;
      if (error.error) {
        const { error_subcode, error_user_msg } = error.error;
        //TODO: Add Links to videos for these issues
        // TOS
        if (error_subcode === 1815089) {
          actions.showNotification(error_user_msg, "warning", "long");
        }
        // Payment Method
        if (error_subcode === 1359188) {
          actions.showNotification(error_user_msg, "warning", "long");
        }
      }
    }
  };

  // const getCampaign = async (id: string) => {
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setCurrentCampaign(campaign);
  // };

  // const addCampaignToUrl = (id: string) => {
  //   window.history.pushState(
  //     { info: "Creating New Campaign" },
  //     "Create Campaign",
  //     CURRENT_URL_CAMPAIGN.replace(":campaignId", id)
  //   );
  // };
  // const createCampaignToUrl = () => {
  //   window.history.pushState(
  //     { info: "Creating New Campaign" },
  //     "Create Campaign",
  //     CURRENT_URL_CREATE
  //   );
  // };
  const renderCampaignForm = React.useMemo(
    () => <CreateCampaignForm imageRef={imageRef} image={image} />,
    [imageRef.current, image]
  );

  React.useEffect(() => {
    console.log(firebase.auth().currentUser);
  }, []);
  React.useEffect(() => {
    if (state.campaigns.isModalOpen) {
      setImage("");
    }
  }, [state.campaigns.isModalOpen]);

  return (
    <div className="py-10">
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Dashboard
              </h1>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={actions.openCreateModal}
                // onClick={createTempCampaign}
              >
                Create Campaign
              </button>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <CampaignList />
          <input
            type="file"
            ref={imageRef}
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
        </div>
        <Modal
          showDelete={state.campaigns.currentCampaign !== null}
          open={state.campaigns.isModalOpen}
          onClose={actions.closeCampaignModal}
        >
          <div>
            {state.campaigns.loading ? (
              "Campaign Loading"
            ) : state.campaigns.currentCampaign ? (
              <CurrentCampaign />
            ) : (
              renderCampaignForm
            )}
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default CampaignPage;
