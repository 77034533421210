import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";
import rootReducer from "../reducer";
// Persistence State

const persistConfig = {
  key: 'root',
  storage: localStorage
}

export default ( initialState = {}, history: any ) => {
  const persistedReducer = persistReducer(persistConfig,rootReducer)

  const store = createStore(
    persistedReducer,
    initialState
  )

  const persistor = persistStore(store)

  return { store, persistor }
}
export type RootState = any
// export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = any
// export type AppDispatch = typeof store.dispatch;
