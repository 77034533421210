import {
  PlusIcon,
  PencilAltIcon,
  XCircleIcon,
  BadgeCheckIcon,
} from "@heroicons/react/outline";
import firebase from "firebase";
import FacebookLogin from "react-facebook-login";
import classNames from "classnames";
import * as React from "react";
import Divider from "../components/Divider";
import {
  facebookLogin,
  getFBPageAndMarketingAccountInfo,
  saveFBPageMarketingAccountInfo,
} from "../api/requests/facebookRequests";
import { AuthContext } from "../FirebaseAuthContext";
import { RootState } from "../store/store";
import { useDispatch, useSelector } from "react-redux";
import { createNotification } from "../actions/notificationAction";
import FBSettingSelectMenu, {
  IOption,
} from "../components/FBSettingSelectMenu";
import { stringify } from "node:querystring";

export interface SettingsPageProps {}

/**
 *  TODO: Add loading state for all the ajax request
    TODO: Have a function to update when the user unlinks the account
 */
const SettingsPage: React.FunctionComponent<SettingsPageProps> = () => {
  const authValue = React.useContext(AuthContext);
  const [updatedUserInfo, setUpdatedUserInfo] = React.useState<any | null>();
  const [tokenEdit, setTokenEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [checkIsLinked, setCheckIsLinked] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  const [page, setPage] = React.useState<IOption>();
  const [adAccounts, setAdAccounts] = React.useState([]);
  const [adAccount, setAdAccount] = React.useState<IOption>();
  const dispatch = useDispatch();
  const [isLinked, setIsLinked] = React.useState(false);

  const userState = useSelector((state: RootState) => state.userInfo);

  const actions = React.useMemo(
    () => ({
      showNotification: createNotification(dispatch),
    }),
    [dispatch]
  );

  const saveAccountInfo = async () => {
    try {
      setLoading(true);
      if (!page?.id || !adAccount?.id) {
        throw new Error("Please select your page and ad account");
      }
      await saveFBPageMarketingAccountInfo(
        authValue.user.uid,
        page.id,
        adAccount.id
      );
      setLoading(false);
      actions.showNotification(
        "Successfully Saved your configuration",
        "success",
        "short"
      );
      // Updated values
      await getUpdatedUserInfo();
      await getPageAndAccontInfo();
    } catch (err) {
      actions.showNotification(
        "Could not save the configuration",
        "error",
        "short"
      );
    } finally {
      setLoading(false);
    }
    console.log(page, adAccount);
  };
  const onFbLogin = async (data: Record<string, string>) => {
    try {
      await facebookLogin(data.accessToken, data.id, authValue.user.uid);
      await getUpdatedUserInfo();
      // update the user info
      actions.showNotification(
        "Successfully Linked your account",
        "success",
        "short"
      );
    } catch (err) {
      // Do something
      actions.showNotification("Could not link your account", "error", "short");
    }

    /* console.log('sending',data.accessToken, data.id) */
  };
  const toggleTokenEdit = () => setTokenEdit(!tokenEdit);
  const checkForFbLinked = async (userInfo: any | null) => {
    try {
      if (!userInfo) {
        console.log("user does not exist");
        setIsLinked(false);
        return;
      }
      const user = userInfo;
      if (!user || !user.fbToken) {
        console.log("user no token");
        setIsLinked(false);
        return;
      }
      // const hasExpired =
      //   Date.now() <= new Date(user.fbToken.expirationDate.toDate()).getTime();
      // setIsLinked(hasExpired);
      setIsLinked(true);
    } catch (err) {
      // Do something
      console.log(err);
      setIsLinked(false);
    } finally {
      setCheckIsLinked(true);
    }
  };
  const getPageAndAccontInfo = async () => {
    try {
      const result = await getFBPageAndMarketingAccountInfo(authValue.user.uid);
      const { pages, adAccounts } = result.data;
      const selectedPage = pages.find(
        (page: { name: string; id: string }) =>
          page.id === updatedUserInfo.pageId
      );

      const selecetedAccount = adAccounts.find(
        (account: { name: string; id: string }) =>
          account.id === updatedUserInfo.adAccountId
      );
      setPages(pages);
      setPage(selectedPage);
      setAdAccounts(adAccounts);
      setAdAccount(selecetedAccount);
    } catch (err) {
      console.log(err);
    }
  };
  const getUpdatedUserInfo = async () => {
    try {
      const userResponse = await firebase
        .firestore()
        .collection("users")
        .doc(authValue.user.uid)
        .get();

      setUpdatedUserInfo(userResponse.exists ? userResponse.data() : null);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    getUpdatedUserInfo();
  }, []);

  React.useEffect(() => {
    checkForFbLinked(updatedUserInfo);
  }, [updatedUserInfo]);
  React.useEffect(() => {
    if (isLinked) {
      getPageAndAccontInfo();
    }
  }, [isLinked]);
  return (
    <div className="py-10">
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Settings
              </h1>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-3xl mx-auto sm:px-6 lg:px-8">
          <div className="bg-white shadow h-screen overflow-auto  sm:rounded-lg p-5">
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Profile
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                None of this information will be shared.
              </p>
            </div>
            <div className="py-3">
              <div className="px-3 py-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={`${userState.firstName} ${userState.lastName}`}
                    disabled={true}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-1/3 sm:text-sm border border-gray-300 p-2 rounded-md"
                    placeholder="Jack"
                  />
                </div>
              </div>
              <div className="px-3 py-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    disabled={true}
                    value={userState.email}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-1/3 sm:text-sm border border-gray-300 p-2 rounded-md"
                    placeholder="jack@gmail.com"
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Membership Info (Comming Soon)
              </h2>
              {/* <p className="mt-1 text-sm text-gray-500">
                  This determines what features your account has.
                  </p>
                  </div>
                  <div className="py-3">
                  <div className="px-3">
                  <span className="text-md font-medium text-gray-700">
                  Membership Type
                  </span>
                  <div className="flex justify-between items-center">
                  <p className="text- text-gray-600">$5 Lifetime</p>
                  <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                  <PlusIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                  />
                  Upgrade
                  </button>
                  </div>
                  </div> */}
            </div>
            <Divider />
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Facebook Integration
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                {/* This information will be displayed publicly so be careful what
                      you share. */}
              </p>
              <div className="py-3">
                <div className="px-3 py-2 flex ">
                  <FacebookLogin
                    appId="867259983852069"
                    autoLoad={false}
                    authType="reauthenticate"
                    fields="name,email,picture"
                    callback={onFbLogin}
                    textButton="Link with Facebook"
                    icon="fa-facebook"
                    size="small"
                  />
                  {checkIsLinked && (
                    <div className="p-2">
                      {isLinked ? (
                        <p className="flex">
                          <BadgeCheckIcon
                            className="-ml-0.5 mr-2 h-6 w-6 text-green-400"
                            aria-hidden="true"
                          />
                          <span>Linked</span>
                        </p>
                      ) : (
                        <p className="flex">
                          <BadgeCheckIcon
                            className="-ml-0.5 mr-2 h-6 w-6 text-red-400"
                            aria-hidden="true"
                          />
                          <span className="flex">Not Linked</span>
                        </p>
                      )}
                    </div>
                  )}
                  {/* <label
                        htmlFor="token-id"
                        className="block text-sm font-medium text-gray-700"
                        >
                        HotFSBO Token Id
                        </label>
                        <div className="mt-1 flex items-center">
                        <input
                        disabled={!tokenEdit}
                        type={tokenEdit ? "text" : "password"}
                        name="token-id"
                        id="token-id"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-1/3 sm:text-sm border border-gray-300 p-2 rounded-md"
                        placeholder="12345"
                        />
                        <button
                        type="button"
                        onClick={toggleTokenEdit}
                        className={classNames(
                        tokenEdit
                        ? "bg-green-500 hover:bg-green-600"
                        : "bg-indigo-600 hover:bg-indigo-700",
                        "ml-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                        >
                        {tokenEdit ? (
                        "Save"
                        ) : (
                        <React.Fragment>
                        <PencilAltIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                        />
                        Edit
                        </React.Fragment>
                        )}
                        </button>
                        </div> */}
                </div>
                {isLinked && (
                  <div className="flex justify-between">
                    <div className="w-1/4">
                      <FBSettingSelectMenu
                        selected={page}
                        label="Page"
                        onChange={setPage}
                        options={pages}
                      />
                    </div>
                    <div className="w-1/4">
                      <FBSettingSelectMenu
                        label="Ad Account"
                        selected={adAccount}
                        onChange={setAdAccount}
                        options={adAccounts}
                      />
                    </div>
                    <div className="w-1/4">
                      <button
                        type="button"
                        disabled={!adAccount || !page}
                        onClick={saveAccountInfo}
                        className={
                          "ml-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm bg-indigo-600 hover:bg-indigo-700 text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        }
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SettingsPage;
