import * as React from "react";
import logo from '../assets/logo_no_text.png'
import LoginForm from "../features/loginForm/LoginForm";

export interface LoginPageProps {}

/**
 * TODO: Connect Start free trail,
 * TODO: Change logo
 * TODO: Change colors
 */
const LoginPage: React.FunctionComponent<LoginPageProps> = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-16 w-auto"
            src={logo}
            alt="logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
