import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import firebase from "firebase";
import "firebase/firestore";
import FirebaseAuthContext, { AuthContext } from "./FirebaseAuthContext";
import { useSelector } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import MainNav from "./features/mainNav/MainNav";
import CampaignPage from "./pages/CampaignPage";
import LeadPage from "./pages/LeadPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import SettingsPage from "./pages/SettingsPage";
import ProtectedRoute from "./ProtectedRoute";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import PopupNotification from "./components/PopupNotification";
import { RootState } from "./store/store";
import CommingSoonOverlay from "./components/CommingSoonOverlay";

const firebaseConfig = {
  apiKey: "AIzaSyB5bRh6glewTVHRnW59VJ45um3GG-lVbSw",
  authDomain: "fbadmanager-2f3a1.firebaseapp.com",
  projectId: "fbadmanager-2f3a1",
  storageBucket: "fbadmanager-2f3a1.appspot.com",
  messagingSenderId: "847690911855",
  appId: "1:847690911855:web:c65abda8bf106f3d05bd9a",
  measurementId: "G-13TG771CKT",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
const fbPixelOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
// Initializing Pixel
ReactPixel.init("807859910128591", undefined, fbPixelOptions);
// Event Page View
ReactPixel.pageView();
function App() {
  const {
    isShown: isNotificationShown,
    message: notificationMessage,
    type: notificationType,
  } = useSelector((state: RootState) => state.notifications);
  return (
    <React.StrictMode>
      <FirebaseAuthContext>
        <Router>
          <div className="h-screen">
            <Helmet>
              <meta charSet="utf-8" />
              <title>HotFSBO Lead Machine</title>
            </Helmet>
            <CommingSoonOverlay />
            <MainNav />
            <div>
              {isNotificationShown && (
                <PopupNotification
                  message={notificationMessage}
                  type={notificationType}
                />
              )}
            </div>
            <div className="bg-gray-100">
              <Switch>
                <Route path="/" exact>
                  <LoginPage />
                </Route>
                <Route path="/register" exact>
                  <RegisterPage />
                </Route>
                <Route path="/forgotPassword" exact>
                  <ForgetPassword />
                </Route>
                <Route path="/resetPassword" exact>
                  <ResetPassword />
                </Route>
                <ProtectedRoute redirectTo="/" path="/dashboard">
                  <CampaignPage />
                </ProtectedRoute>
                <ProtectedRoute redirectTo="/" path="/leads">
                  <LeadPage />
                </ProtectedRoute>
                <ProtectedRoute redirectTo="/" path="/settings">
                  <SettingsPage />
                </ProtectedRoute>
              </Switch>
            </div>
          </div>
        </Router>
      </FirebaseAuthContext>
    </React.StrictMode>
  );
}

export default App;
