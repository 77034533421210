import { Dispatch } from "redux"

export const SHOW_NOTIFICATION = 'show_notification'
export const HIDE_NOTIFICATION = 'hide_notification'


const showNotification = (message:string, type: 'success' | 'warning' | 'error' | 'info') => ({
    type: SHOW_NOTIFICATION,
    payload: {
        message,
        type,
    }
})

const hideNotification = () => ({
    type: HIDE_NOTIFICATION,
})

export const createNotification = (dispatch: Dispatch) => (message:string, type: 'success' | 'warning' | 'error' | 'info', duration: 'short' | 'long') => {

    try {
        const timeout = duration === 'short' ? 5: 8;

        dispatch(showNotification(message, type))
        setTimeout(() => dispatch(hideNotification()), timeout * 1000 )
    } catch (err) {
        console.log(err)
        dispatch(hideNotification())
    }

}

export const clearNotification = ( dispatch: Dispatch ) => () => dispatch(hideNotification())
