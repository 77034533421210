import * as React from "react";

export interface DividerProps {}

const Divider: React.FunctionComponent<DividerProps> = () => {
  return (
    <div className="relative py-2">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
    </div>
  );
};

export default Divider;
