import * as React from "react";
import { useHistory, Link } from "react-router-dom";
import { LockClosedIcon } from "@heroicons/react/solid";
import firebase from "firebase";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loginUserInfo } from "../../actions/userActions";

export interface LoginFormProps {}

/**
 * TODO: add form validation
 * TODO: add notification for form messages
 * TODO: connoct with forget password page
 */
const LoginForm: React.FunctionComponent<LoginFormProps> = () => {
  const [loading, setLoading] = React.useState(false);
  const [loginError, setLoginError] = React.useState<string | null>(null);
  const { register, handleSubmit } = useForm();

  const history = useHistory();
  const dispatch = useDispatch();

  const userActions = React.useMemo(
    () => ({
      loginUser: loginUserInfo(dispatch),
    }),
    [dispatch]
  );

  const submit = async (data: Record<string, string>) => {
    try {
      setLoginError(null);
      setLoading(true);
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password);
      if (!response.user) {
        throw Error("Something went wrong try again at a later time");
      }
      const userInfo = await firebase
        .firestore()
        .collection("users")
        .doc(response.user.uid)
        .get();

      console.log("user info", userInfo.data());
      userActions.loginUser(userInfo.data());
      history.push("/dashboard");
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        setLoginError("Invalid Email or User does not exist");
      }
      if (err.code === "auth/wrong-password") {
        setLoginError("Password does not match");
      }
      // Do something
      console.error(err);
    } finally {
      setLoading(false);
    }
    // history.push("/dashboard");
  };
  const sendToRegister = () => {
    history.push("/register");
  };
  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit(submit)}>
      {loginError && <span className="text-sm text-red-600">{loginError}</span>}
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            id="email-address"
            type="email"
            autoComplete="email"
            //required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Email address"
            {...register("email")}
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            id="password"
            type="password"
            autoComplete="current-password"
            //required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Password"
            {...register("password")}
          />
        </div>
      </div>

      <div className="flex items-center justify-between">
        {/* <div className="flex items-center">
          <input
            id="remember_me"
            name="remember_me"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label
            htmlFor="remember_me"
            className="ml-2 block text-sm text-gray-900"
          >
            Remember me
          </label>
        </div> */}

        <div className="text-sm">
          <Link
            to="/forgotPassword"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Forgot your password?
          </Link>
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
              aria-hidden="true"
            />
          </span>
          {loading ? "Loading..." : "Sign In"}
        </button>
        <button
          className="group relative w-full flex justify-center my-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={sendToRegister}
        >
          Register
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
