import { AnyAction } from "redux";
import { LOGIN_USER, LOGOUT_USER } from "../actions/userActions";

interface UserType {
    firstName: string,
    lastName: string,
    email: string,
    campaigns: any[]
}
const initialState: UserType = {
    firstName: '',
    lastName: '',
    email: '',
    campaigns: []
};

export default function UserReducer(
  state = initialState,
  action: AnyAction
) {
    switch(action.type){

    case LOGIN_USER: {
        const { firstName, lastName, email, campaigns } = action.payload
        return {
            ...state,
            firstName ,
            lastName,
            email,
            campaigns
        };
    }
    case LOGOUT_USER: {
        return {
            firstName: '',
            lastName: '',
            email: '',
            campaigns: []
        }
    }
    default:
      return state;

    }
}
