import * as React from "react";
import firebase from "firebase";
import ReactPixel from "react-facebook-pixel";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import logo from "../assets/logo_no_text.png";
import { removeCode, validateCode } from "../api/requests/codeRequests";
import { createUser } from "../api/requests/userRequests";
import { createNotification } from "../actions/notificationAction";
import { useDispatch } from "react-redux";

export interface RegisterPageProps {}

const RegisterPage: React.FunctionComponent<RegisterPageProps> = () => {
  const [loading, setLoading] = React.useState(false);
  const [isCodeValid, setIsCodeValid] = React.useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const actions = React.useMemo(
    () => ({
      showNotification: createNotification(dispatch),
    }),
    [dispatch]
  );

  const goBackToLogin = () => history.push("/");

  const submit = async (data: Record<string, string>) => {
    try {
      setIsCodeValid(true);
      setLoading(true);
      const validCode = await validateCode(data.code);
      if (!validCode.data.result) {
        setIsCodeValid(false);
        throw new Error("Code invalid");
      }
      await createUser(
        data.firstName,
        data.lastName,
        data.phone,
        data.email,
        data.password
      );

      await removeCode(data.code);
      actions.showNotification(
        "Your account has been created",
        "success",
        "short"
      );
      ReactPixel.track("Lead", data.email);
      reset();
    } catch (err) {
      console.error(err);
      actions.showNotification(err.message, "error", "short");
    } finally {
      setLoading(false);
    }
    // history.push("/dashboard");
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-16 w-auto" src={logo} alt="logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Register
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(submit)}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="firstName" className="sr-only">
                First Name
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="firstName"
                  type="text"
                  autoComplete="firstName"
                  //required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="First Name"
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <>
                    <div className="absolute top-3 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                    <p
                      className="mt-1 pl-1 text-sm text-red-600"
                      id="email-error"
                    >
                      First Name Required
                    </p>
                  </>
                )}
              </div>
            </div>
            <div>
              <div className="mt-1 relative rounded-md shadow-sm">
                <label htmlFor="lastName" className="sr-only">
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  autoComplete="lastName"
                  //required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Last Name"
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <>
                    <div className="absolute top-3 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                    <p
                      className="mt-1 pl-1 text-sm text-red-600"
                      id="email-error"
                    >
                      Last Name Required
                    </p>
                  </>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="email-address"
                  type="email"
                  autoComplete="email"
                  //required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <>
                    <div className="absolute top-3 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                    <p
                      className="mt-1 pl-1 text-sm text-red-600"
                      id="email-error"
                    >
                      Email Required
                    </p>
                  </>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="phone" className="sr-only">
                Phone
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="phone"
                  type="text"
                  //required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="3232423414"
                  {...register("phone", {
                    required: true,
                    pattern: new RegExp(
                      "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
                    ),
                  })}
                />
                {errors.phone && (
                  <>
                    <div className="absolute top-3 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                    {errors.phone.type === "required" && (
                      <p
                        className="mt-1 pl-1 text-sm text-red-600"
                        id="email-error"
                      >
                        Phone Required
                      </p>
                    )}
                    {errors.phone.type === "pattern" && (
                      <p
                        className="mt-1 pl-1 text-sm text-red-600"
                        id="email-error"
                      >
                        Phone must be valid
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  //required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  {...register("password", { required: true, minLength: 6 })}
                />
                {errors.password && (
                  <>
                    <div className="absolute top-3 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                    {errors.password.type === "minLength" && (
                      <p
                        className="mt-1 pl-1 text-sm text-red-600"
                        id="email-error"
                      >
                        Password must be atleast 6 characters
                      </p>
                    )}
                    {errors.password.type === "required" && (
                      <p
                        className="mt-1 pl-1 text-sm text-red-600"
                        id="email-error"
                      >
                        Password Required
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="code" className="sr-only">
                One-time Code
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="code"
                  type="text"
                  //required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="code"
                  {...register("code", { required: true })}
                />
                {(errors.code || !isCodeValid) && (
                  <>
                    <div className="absolute top-3 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                    {errors.code?.type === "required" && (
                      <p
                        className="mt-1 pl-1 text-sm text-red-600"
                        id="email-error"
                      >
                        Code Required
                      </p>
                    )}
                    {!isCodeValid && (
                      <p
                        className="mt-1 pl-1 text-sm text-red-600"
                        id="email-error"
                      >
                        Invalid Code
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div>
            <button
              disabled={loading}
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
              {loading ? "Loading..." : "Register"}
            </button>
          </div>
          <div>
            <button
              onClick={goBackToLogin}
              className="group relative w-full flex justify-center my-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
