import axios from "../axios";

export const facebookLogin = async (
  accessToken: string,
  accountId: string,
  docId: string
) => {
  try {
    const response = await axios({
      method: "post",
      url: "/users/login/fb",
      data: { accessToken, accountId, docId },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
export const saveFBPageMarketingAccountInfo = async (
  docId: string,
  pageId: string,
  adAccountId: string
) => {
  try {
    const response = await axios({
      method: "post",
      url: "/users/save/fbPageAccount",
      data: { pageId, adAccountId, docId },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
export const getFBPageAndMarketingAccountInfo = async (docId: string) => {
  try {
    const response = await axios({
      method: "get",
      url: `/users/fbPageAccount/${docId}`,
    });
    // Mapping the pages to only id and name
    response.data.pages = response.data.pages.map((page: any) => ({
      id: page.id,
      name: page.name,
    }));
    return response;
  } catch (err) {
    throw err;
  }
};
/* export const saveAccountId = async (accountId: string) => {
 *   try {
 *     const response = await axios({
 *       method: "get",
 *       url: `/token/${accessToken}`,
 *     });
 *     return response;
 *   } catch (err) {
 *     throw err;
 *   }
 * } */
