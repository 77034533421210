import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import * as React from "react";

export interface LeadActionMenuProps {}

const LeadActionMenu: React.FunctionComponent<LeadActionMenuProps> = () => {
  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              type="button"
              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white hover:bg-gray-50 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              Actions
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 "
            >
              <Menu.Item>
                <div
                  className={classNames(
                    "cursor-pointer",
                    "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 "
                  )}
                >
                  Download
                </div>
              </Menu.Item>
              <Menu.Item>
                <div
                  className={classNames(
                    "cursor-pointer",
                    "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 "
                  )}
                >
                  Send To CRM
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default LeadActionMenu;
