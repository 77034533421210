import * as React from "react";
import firebase from "firebase";
import { useDispatch } from "react-redux";
import { getCampaignById } from "../../actions/campaignActions";
import CampaignCard from "../../components/CampaignCard";
import CurrentCampaign from "../../components/CurrentCampaign";
import { AuthContext } from "../../FirebaseAuthContext";

export interface CampaignListProps {}

const CampaignList: React.FunctionComponent<CampaignListProps> = () => {
  const dispatch = useDispatch();
  const userAuth = React.useContext(AuthContext);
  const [campaigns, setCampaigns] = React.useState<any[]>([]);

  const actions = React.useMemo(
    () => ({
      openModal: getCampaignById(dispatch),
    }),
    [dispatch]
  );

  const getCampaigns = async () => {
    try {
      const userInfo = await firebase
        .firestore()
        .collection("users")
        .doc(userAuth.user.uid)
        .get();
      //@ts-expect-error
      const { campaigns: campaignObject } = userInfo.data();

      const campaigns = Object.entries<Record<string, any>>(campaignObject).map(
        ([id, values]) => ({
          id,
          onClick: (id: string) => actions.openModal(id),
          ...values,
        })
      );
      console.log(campaigns);
      setCampaigns(campaigns);
    } catch (err) {
      // Do something
      console.error(err);
    }
  };

  React.useEffect(() => {
    getCampaigns();
  }, []);

  /* const campaign = {
   *   campaignId: "123",
   *   name: "Jane Cooper",
   *   title: "Paradigm Representative",
   *   role: "Admin",
   *   email: "janecooper@example.com",
   *   telephone: "+1-202-555-0170",
   *   imageUrl:
   *     "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
   *   onClick: (id: string) => actions.openModal(id),
   * }; */
  if (campaigns.length === 0) {
    return (
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-4">
        <div className="col-span-6">No Campaigns Created</div>
      </ul>
    );
  }
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-4">
      {campaigns.map((campaign) => (
        <CampaignCard {...campaign} />
      ))}
    </ul>
  );
};

export default CampaignList;
