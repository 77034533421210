import React from 'react';

interface ResetPasswordType {

}

const ResetPassword:React.FunctionComponent<ResetPasswordType> = () => {
    return (
        <div>
            div
        </div>
    )
};
export default ResetPassword;
